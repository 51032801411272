#root {
	height:100%;
}
.App {
  text-align: center;
  position:relative;
  height:100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Player ratio: 100 / (1280 / 720) = 100 / (1920 / 1080) */
/*
.player-wrapper {
    padding-top: 56.25%;
    height: 100%;
    width: 100%;
    top: 0;
}
*/

.react-player {
  	position: absolute;
  	top: 0;
  	left: 0;
}

.overlay {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:100;
}
.controls {
	position:absolute;
    bottom: 70px;
    left: 50%;
    transform: translate(-50%);
    width:fit-content;
}

.narration {
	position: absolute;
    bottom: 180px;
    background: #101519a3;
    color: #ddd;
    left: 50%;
    transform: translate(-50%);
    width: calc(100% - 25rem);
    max-height: calc(100% - 25rem);
    height:min-content;
    overflow-y:auto;
/*    outline:1px solid red;*/
}

.narration > div {
	padding:30px 50px;
    font-size: 3em;
    line-height:1.3em;
/*
	outline:1px solid green;
	outline-style:auto;
*/
}

.narration p {margin:.3em}

button {
	border: 2px solid #7d97ac4f;
    background: #7f9eb657;
    color: #ddd;
    padding: 17px 34px;
    text-transform: uppercase;
    font-size: 1.4em;
    font-weight: bold;
    letter-spacing: 0.1em;
    margin:0 20px;
    transition:all .3s;
}

button:hover, button:active, button:focus {
	background: #e98cc357;/*#7f9eb687*/
    color: #eee;
}

.dev-panel {
	position: absolute;
    top: 20px;
    right: 20px;
    font-size: 1.1em;
    background: #647d8354;
    color: #dbdbdb52;
    outline: 1px solid #6bd8ff42;
    transition:all .2s;

/*
    background: #838364ab;
	color:#dbdbdb;
*/
}
.dev-panel:hover, .dev-panel:hover .close {
	color:#dbdbdb;
    background: #317394ab;
}
.dev-panel .close {
	position:absolute;
	left:-36px;
	top:-8px;
    background: #647d8354;
    color: #dbdbdb52;
	border-radius:20px;
	padding:8px 8px;
	font-size:2.3em;
	line-height:.5em;
	font-weight:bold;
    cursor:pointer;
}
.dev-panel .text {
    padding: 7px 20px;
    text-align: right;
    line-height:1.4em;
}
.dev-panel .text span {
	border-bottom: 1px dotted #779cb7;
    color: #779cb7;
    cursor:pointer;
}
.dev-panel .progress {
	position: relative;
	height: 20px;
/*    background: #999;	*/
	border-bottom:1px solid #fffb6b21;
}
/* time */
.dev-panel .progress span {
	display:block;
    text-align:center;
    font-weight: bold;
    font-size: 0.8em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #dff;
}
.dev-panel:hover .progress span {
    color: #eee;
}
.dev-panel .progress div {
	position: absolute;
    height: 20px;
    width: 0;
    height: 100%;
    left:0;
    top:0;
    transition:all 0.3s;
}

.dev-panel .progress .loaded {
    background: #b8d4e51c;
}

.dev-panel .progress .played {
	background: #3293e963;
    border-right: 3px solid #1b6da9;
}


.fullscreen {
	position:absolute;
    right: 40px;
    bottom: 70px;
    color: #5d708196;
    font-size: 2.5em;
    cursor:pointer;
}
.fullscreen:hover {
	color:#8aa5bdbb;
}


.tree-panel {
	position: absolute;
    top: 20px;
    right: 50%;
    font-size: 1.1em;
    background: #647d83aa;
    color: #dbdbdb52;
    outline: 1px solid #6bd8ff42;
    transition:all .2s;

/*
    background: #838364ab;
	color:#dbdbdb;
*/
}


.diagram-component {
  width: 700px;
  height: 400px;
  border: solid 1px black;
  background-color: #8888;
}

